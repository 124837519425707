import axios, { AxiosInstance } from "axios";
import { getCookie, removeCookie } from "goi_common";

// 권한 체크 함수들을 분리
async function checkSuperuser(token: string) {
  try {
    await axios.get(`/api/superusercheck/?token=${token}`);
    return true;
  } catch {
    return false;
  }
}

async function checkCsManager(token: string) {
  try {
    await axios.get(`/api/csmanagercheck/?token=${token}`);
    return true;
  } catch {
    return false;
  }
}

// 토큰 검증 및 권한 체크 함수
async function validateTokenAndPermission(instance: "super" | "cs", token: string) {
  if (!token) {
    throw new Error("No token provided");
  }

  if (instance === "super") {
    const isSuperuser = await checkSuperuser(token);
    if (!isSuperuser) {
      throw new Error("Not authorized as superuser");
    }
  } else if (instance === "cs") {
    const isSuperuser = await checkSuperuser(token);
    if (isSuperuser) return; // superuser면 추가 체크 불필요

    const isCsManager = await checkCsManager(token);
    if (!isCsManager) {
      throw new Error("Not authorized as CS manager");
    }
  }
}

function redirectToLogin() {
  removeCookie("teamjang_token");
  if (typeof window !== "undefined" && window.location.pathname !== "/login/") {
    window.location.href = "/login/";
  }
}

// 기본 axios 인스턴스 생성 함수
function createAxiosInstance(
  baseURL: string | undefined,
  validateAs?: "super" | "cs",
): AxiosInstance {
  const instance = axios.create({
    withCredentials: true,
    baseURL,
  });

  // Request Interceptor
  instance.interceptors.request.use(
    async (config) => {
      try {
        const token = getCookie("teamjang_token");

        if (validateAs) {
          await validateTokenAndPermission(validateAs, token);
        }

        return config;
      } catch (error) {
        redirectToLogin();
        return Promise.reject(config);
      }
    },
    (error) => {
      console.log("TTS", error);
      redirectToLogin();
      return Promise.reject(error);
    },
  );

  // Response Interceptor
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          await instance.post("/users/refresh/");
          return instance(originalRequest);
        } catch {}
      }
      return Promise.reject(error);
    },
  );

  return instance;
}

// Axios instances
// 일반 API 인스턴스 (권한 체크 필요)
export const Axios = createAxiosInstance(process.env.NEXT_PUBLIC_BASE_URL, "super");
export const CsManagerAxios = createAxiosInstance(process.env.NEXT_PUBLIC_BASE_URL, "cs");
